<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- <div>
      <p>{{ this.rowData }}</p>
    </div> -->
    <!-- {{ this.rows }} -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <b-button variant="danger" @click="$router.push({ name: 'returnkuruplun' })"> กลับ </b-button>

          <span v-if="status_user == 'ผู้ใช้ทั่วไป'"></span>
          <span v-else>
            <span v-if="this.rowData.accept_status !== 'รับทราบ'"
              ><b-button variant="primary ml-1" @click="examine"> ตรวจสอบ </b-button></span
            >
            <span v-else></span>
          </span>
          <b-button variant="outline-primary ml-1" @click="exportpdf"> พิมพ์ใบคืน </b-button>

          <div class="title head">
            <p class="text-center h1">รายการส่งคืนทรัพย์สิน</p>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">เลขทะเบียน :</div>
            <div class="ml-1 h4">
              {{ this.rowData.equipment_code }}
            </div>
            <!-- <div class="ml-1 h4 text-primary" text="primary">วันที่ :</div>
            <div class="ml-1 h4">13-ม.ค-2565</div> -->
            <!-- <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ: :</div>
            <div class="ml-1 h4">2565</div> -->
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary">กลุ่มงาน :</div>
            <div class="ml-1 h4">{{ this.rowData.work_gname }}</div>
            <div class="ml-1 h4 text-primary">ฝ่ายงาน:</div>
            <div class="ml-1 h4">{{ this.rowData.dep_name }}</div>
            <div class="ml-1 h4 text-primary">ผู้คืน:</div>
            <div class="ml-1 h4">{{ this.rowData.return_name }}</div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary">วันที่เบิก:</div>
            <div class="ml-1 h4">
              {{ this.rowData.req_date_th }}
            </div>
            <div class="ml-1 h4 text-primary">วันที่คืน:</div>

            <div class="ml-1 h4">
              {{ this.rowData.return_date_th }}
            </div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">รายละเอียด :</div>
            <div class="ml-1 h4">
              {{ this.rowData.return_reason }}
            </div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">สถานะทรัพย์สิน :</div>
            <div class="ml-1 h4">{{ this.rowData.status }}</div>
          </div>

          <hr />
          <div class="row justify-content-end">
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      <span>Excel</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item @click="exportpdf">
                      <feather-icon icon="ClipboardIcon" class="mr-50" />
                      <span>PDF</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'ลำดับที่'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.ลำดับที่ }}</span>
                </span>
                <span v-if="props.column.field === 'image'" class="text-nowrap">
                  <b-button v-b-modal="`modal-image${props.index}`" @click="get_image(props.row)">
                    <feather-icon icon="ImageIcon" class="mr-50" />
                  </b-button>
                  <b-modal :id="`modal-image${props.index}`" ok-only ok-title="ปิด">
                    <b-card-text class="text-center">
                      <!-- {{ image_p }} -->
                      <div v-if="image_p != null || image_p != undefined">
                        <swiper
                          ref="mySwiper"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="swiperOptions"
                        >
                          <swiper-slide v-for="image_p in image_p" :key="image_p">
                            <b-img
                              width="250%"
                              height="250%"
                              src
                              :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                              class="mx-1"
                            />
                          </swiper-slide>
                          <div slot="button-next" class="swiper-button-next" />
                          <div slot="button-prev" class="swiper-button-prev" />
                          <div slot="pagination" class="swiper-pagination" />
                        </swiper>
                      </div>
                      <span v-else>
                        <div class="text-center">ไม่มีรูปภาพ</div>
                      </span>
                    </b-card-text>
                  </b-modal>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
                  <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import { data } from "vue-echarts";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import _ from "lodash";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);

export default {
  props: {
    rowData: Object,
  },
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      API: `${API}`,
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
      show: false,
      pageLength: 25,
      currentPage: 1,
      total: "",
      dir: false,
      selected: null,

      columns: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "SERIAL NUMBER",
          field: "serial_number",
        },
        {
          label: "รายการ",
          field: "e_name",
        },
        {
          label: "หน่วยนับ",
          field: "unittype",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ราคาต่อหน่วย + vat 7%",
          field: "unitprice_vat",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ดูรูปภาพ",
          field: "image",
        },
        // {
        //   label: "เหตุผลที่ส่งคืน",
        //   field: "เหตุผลที่ส่งคืน",
        // },
        // {
        //   label: "สถานะ",
        //   field: "สถานะ",
        // },
      ],
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      rows: [],
      searchTerm: "",
      status_user: "",
      image_p: "",
      token: "",
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  mounted() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    this.returns_History();
  },
  async created() {
    _.isEmpty(this.rowData) ? this.$router.push({ path: "/return-equipment" }) : true;

    const { usertype } = await JSON.parse(localStorage.getItem("username_type"));
    // console.log(usertype);
    this.status_user = await usertype;
    // console.log(this.status_user);
  },
  methods: {
    async get_image(value) {
      console.log(value);
      this.image_p = null;
      // console.log(value.e_number);
      const { access_token } = await this.access_token();
      this.token = access_token;
      const api = `${API}equipmentDetail?e_number=${value.e_number}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(api, head);
      // console.log(res.data.message.data[0].e_image);
      if (res.data.message.data[0].e_image) {
        this.image_p = res.data.message.data[0].e_image;
        this.image_p = JSON.parse(this.image_p);
        this.image_p = this.image_p;
      } else {
        this.image_p = null;
      }
    },
    examine() {
      Swal.fire({
        title: "คุณยื่นยันการตรวจสอบใช่หรือไม่",
        text: `ตรวจสอบ`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },

        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const { access_token } = await this.access_token();
          const url = `${API}staffApproveReturn/${this.rowData.equipment_code}`;
          const data = {
            accept_status: "รับทราบ",
          };
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          const res = await axios.put(url, data, head);
          // console.log(res);
          Swal.fire({
            icon: "success",
            title: "ตรวจสอบสำเร็จ!",
            text: `ตรวจสอบสำเร็จ`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: `รายการส่งคืนทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
        columns: [
          {
            title: "รายการส่งคืนทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: `เลขทะเบียน: ${this.rowData.equipment_code}`,
                headerCellOptions: { textAlign: "" },
                children: [
                  {
                    title: `กลุ่มงาน: ${this.rowData.work_gname}`,
                    headerCellOptions: { textAlign: "" },
                    children: [
                      {
                        title: `วันที่เบิก: ${this.rowData.req_date_th} วันที่คืน: ${this.rowData.return_date_th}`,
                        headerCellOptions: { textAlign: "" },
                        children: [
                          {
                            title: "หมายเลขทรัพย์สิน",
                            field: "e_number",
                          },
                          {
                            title: "SERIAL NUMBER",
                            field: "serial_number",
                          },
                          {
                            title: "รายการ",
                            field: "e_name",
                          },
                          {
                            title: "หน่วยนับ",
                            field: "unittype",
                          },
                          {
                            title: "ราคาต่อหน่วย",
                            field: "unitprice_ex",
                            type: "number",
                          },
                          {
                            title: "ราคาต่อหน่วย + vat 7%",
                            field: "unitprice_vat_ex",
                            type: "number",
                          },
                        ],
                      },
                      {},
                    ],
                  },
                  {},
                ],
              },
              {},
            ],
          },
        ],
      });
    },
    perPageChanged(value) {
      console.log(value);
      this.pageLength = value.currentPerPage;
      this.returns_History();
    },
    pageChanged(value) {
      console.log(value);
      this.currentPage = value.currentPage;
      this.returns_History();
    },
    async returns_History() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}returnsHistory?equipment_code=${this.rowData.equipment_code}&_page=${this.currentPage}&_limit=${this.pageLength}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      this.total = res.data.message.total;
      this.rows = res.data.message.data.map((el) => {
        return {
          ...el,
          unitprice_ex: Number(el.unitprice),
          unitprice_vat_ex: Number(el.unitprice_vat),
        };
      });
      this.show = false;
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    exportpdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [5, 190, 5, 250],
        pageSize: "A4",
        pageOrientation: "portrait",
        info: {
          title: "ใบส่งคืนทรัพย์สิน",
        },
        header: [
          {
            text: `เลขที่ ${this.rowData.equipment_code.split("-")[0]}-${this.rowData.equipment_code.split("-")[1]}/${
              this.rowData.equipment_code.split("-")[2]
            }            
            วันที่ ${this.rowData.return_date_th}`,
            style: "header",
            alignment: "right",
            margin: [0, 20, 20, 20],
          },
          {
            text: "ใบส่งคืนทรัพย์สิน",
            style: "header",
            margin: [0, 15, 0, 0],
          },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
          },
          {
            text: `ข้าพเจ้า (นาย,นาง,นาสาว): ${this.rowData.return_name}                                           ตำแหน่ง: ${this.rowData.dep_name}
            
            มีความประสงค์ขอคืนวัสดุ/ทรัพย์สินที่เบิกไว้ใช้ในกลุ่มงาน: ${this.rowData.work_gname}

            เนื่องจาก: ${this.rowData.return_reason}
            `,
            style: "text_header",
            margin: [5, 15, 0, 5],
          },
        ],
        footer: [
          {
            columns: [
              {
                text: `(ลงชื่อ)...................................................................หัวหน้ากลุ่มงาน

                (...................................................................)
                
                วันที่..................................`,
                margin: [0, 0, 0, 0],
                alignment: "center",
                style: "text",
              },

              {
                text: `(ลงชื่อ)...................................................................ผู้ส่งคืนทรัพย์สิน

                (...................................................................)

                วันที่..................................
                `,
                margin: [0, 0, 0, 0],
                alignment: "center",
                style: "text",
              },
            ],
          },

          {
            margin: [10, 10, 10, 0],
            table: {
              widths: ["*", "*"],
              body: [
                [
                  {
                    columns: [
                      {
                        text: `(1) งานพัสดุ ได้รับคืนวัสดุ/ครุภัณฑ์ตามรายงานข้างต้นไว้เรียบร้อยเเล้ว

                        (ลงชื่อ)...................................................................ผู้รับคืนทรัพย์สิน

                        วันที่..................................`,
                        margin: [0, 10, 0, 0],
                        alignment: "center",
                        style: "text",
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: `(2) หัวหน้าเจ้าหน้าที่ลงชื่อรับทราบและสั่งการให้เจ้าหน้าที่บันทึกทะเบียนคุมทรัพย์สิน

                        (ลงชื่อ)...................................................................หัวหน้าเจ้าหน้าที่

                        วันที่..................................
                        `,
                        margin: [0, 10, 0, 0],
                        alignment: "center",
                        style: "text",
                      },
                    ],
                  },
                ],
                [
                  {
                    colSpan: 2,
                    columns: [
                      {
                        text: `(3) งานพัสดุ ลงบัญชีและแก้ไขทะเบียนคุมทรัพย์สิน ตามรายการข้างต้นไว้เรียบร้อยเเล้ว

                      (ลงชื่อ)....................................................................................................

                      วันที่..................................
                      `,
                        margin: [0, 10, 0, 0],
                        style: "text_center",
                      },
                    ],
                  },
                ],
              ],
            },
          },
        ],
        content: [
          {
            text: "รายละเอียดของวัสดุ/ทรัพย์สินที่ส่งคืน",
            fontSize: 9,
            lineHeight: 1,
          },
          {
            style: "tableExample",
            table: {
              headerRows: 2,
              keepWithHeaderRows: 2,
              widths: ["auto", "auto", "*", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                [
                  { text: "ลำดับ", style: "tableHeader", rowSpan: 3 },

                  {
                    text: `หมายเลข
                  ทรัพย์สิน`,
                    style: "tableHeader",
                    rowSpan: 3,
                  },
                  // { text: 'SERIAL NUMBER', style: 'tableHeader' },
                  { text: "รายการ", style: "tableHeader", rowSpan: 3 },
                  { text: "จำนวน", style: "tableHeader", rowSpan: 3 },
                  // { text: 'ราคาต่อหน่วย', style: 'tableHeader' },
                  { text: "เหตุผลที่ส่งคืน", style: "tableHeader", colSpan: 4 },
                  {},
                  {},
                  {},

                  { text: "หมายเหตุ", style: "tableHeader", rowSpan: 3 },
                ],
                [
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "ชำรุด",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: `เสื่อมสภาพ
                    การใช้งาน`,
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: `ใช้การ
                    ไม่ได้`,
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "อื่นๆ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },

                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: ``,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: ``,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },

                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],
                ...this?.rows.map((res, index) => {
                  return [
                    { text: index + 1, style: "text_center" },
                    { text: res.e_number, style: "text_center" },
                    // { text: res.serial_number, style: 'text_center' },
                    { text: res.e_name, style: "text" },
                    { text: "1", style: "text_center" },
                    // {
                    //   text: res.unitprice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
                    //   style: 'text_right',
                    // },
                    { text: `${this.rowData.chk_return_reason == "ชำรุด" ? "ชำรุด" : ""}`, style: "text_center" },
                    {
                      text: `${this.rowData.chk_return_reason == "เสื่อมสภาพการใช้งาน" ? "เสื่อมสภาพการใช้งาน" : ""}`,
                      style: "text_center",
                    },
                    {
                      text: `${this.rowData.chk_return_reason == "ใช้การไม่ได้" ? "ใช้การไม่ได้" : ""}`,
                      style: "text_center",
                    },
                    { text: `${this.rowData.chk_return_reason == "อื่นๆ" ? "อื่นๆ" : ""}`, style: "text_center" },
                    { text: ``, style: "text_center" },
                  ];
                }),
              ],
            },
          },
        ],

        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 5, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          text_header: {
            fontSize: 9,
            bold: true,
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 9,
          },
          text_right: {
            alignment: "right",
            fontSize: 9,
          },
          text: {
            fontSize: 8,
          },
        },
      };
      pdfMake.createPdf(docDefinition).download(`ใบส่งคืนทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
      //pdfMake.createPdf(docDefinition).open();
    },
    getfrom() {
      // console.log(id);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
